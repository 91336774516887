import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'demo',

  ecomm: {
    ...base.ecomm
  },
  featureFlags: {
    ...base.featureFlags,
    enableSentry: true,
    enableSentryFeedbackDialog: true
  },
};
